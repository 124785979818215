























import Vue from 'vue'
import { mapActions } from 'vuex'
import axios from 'axios'
import get from 'lodash/get'
import { errorMessages } from '@/utils/helpers'
import SubmitButton from '@/components/buttons/SubmitButton.vue'

export default Vue.extend({
  name: 'TwoFactorAuth',
  components: { SubmitButton },
  data() {
    return {
      verificationCode: '',
      token: this.$route.query.token,
      qrCode: '',
    }
  },
  methods: {
    ...mapActions(['sendLoginRequest']),
    async submit(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const payload = {
          token: this.token,
          code: this.verificationCode,
        }
        await this.sendLoginRequest(payload)
        await this.$router.push({ name: 'Home' })
      } catch (err) {
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
    apiInstance() {
      return axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.token,
        },
      })
    },
    checkToken(): void {
      if (!this.token) {
        this.$router.push({ name: 'Login' })
      } else {
        this.apiInstance()
          .post('validate-token')
          .then((res) => {
            this.qrCode = get(res, 'data.data.qrCode', null)
          })
          .catch(() => {
            this.$router.push({ name: 'Login' })
          })
      }
    },
  },
  beforeMount() {
    this.checkToken()
  },
})
